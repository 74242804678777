<template>
  <div id="app">
    <nav>
    
        <div class="app_nav">
          <router-link to="/" class="nav_item" @click="handleNavitem(0)">
            <div class="nav_quan"></div>
            <p class="nav_text">首页</p>
          </router-link>
          <router-link to="/works">
            <div class="nav_quan"></div>
            <p class="nav_text">作品集培训</p>
          </router-link>
          <router-link to="/college">
            <div class="nav_quan"></div>
            <p class="nav_text">音乐院校</p>
          </router-link>
          <router-link to="/major">
            <div class="nav_quan"></div>
            <p class="nav_text">音乐专业</p>
          </router-link>
          <router-link to="/tutor">
            <div class="nav_quan"></div>
            <p class="nav_text">音乐导师</p>
          </router-link>
          <router-link to="/information">
            <div class="nav_quan"></div>
            <p class="nav_text">音乐资讯</p>
          </router-link>
          <router-link to="/about">
            <div class="nav_quan"></div>
            <p class="nav_text">艺术留学</p>
          </router-link>
        </div>
   
      <div class="app_title">
        <img src="./assets/img/首页/sanh.png" alt="" class="cha" @click="handleNav">
        <img src="./assets/img/首页/rossotitle.png" alt="" class="rossotitle">
      </div>
    </nav>
    <router-view />
    <div class="newFooter">
      <div class="newTop">
        <div class="newTitle">
          <div class="title">ROSSO 国际艺术教育</div>
          <div class="text">Fashion&Music Education</div>
        </div>
        <div class="newRight">
          <img src="./assets/img/首页/dianhua.png" alt="">
          <p>留学咨询：<span>400-0999-530</span></p>
        </div>
      </div>
      <div class="newBox">
        <div class="newBox_title">音乐留学中心</div>
        <div class="newBox_ul">
          <div class="newBox_li" @click="toggleLocation(0)"> 上海(总部) </div>
          <div class="newBox_li" @click="toggleLocation(1)"> 北京 </div>
          <div class="newBox_li" @click="toggleLocation(2)"> 广州 </div>
          <div class="newBox_li" @click="toggleLocation(3)"> 成都 </div>
        </div>
        <div class="newBox_juti">
          <img src="./assets/img/首页/location.png" alt="">
          <p id="newBox_juti">上海徐汇区文定路208号A座</p>
        </div>
        <div class="newBox_title">艺术留学中心</div>
        <div class="newBox_ul">
          <div class="newBox_li2" @click="toggleLocation2(0)"> 上海(总部) </div>
          <div class="newBox_li2" @click="toggleLocation2(1)"> 北京 </div>
          <div class="newBox_li2" @click="toggleLocation2(2)"> 广州 </div>
          <div class="newBox_li2" @click="toggleLocation2(3)"> 深圳 </div>

          <div class="newBox_li2" @click="toggleLocation2(4)"> 杭州 </div>
          <div class="newBox_li2" @click="toggleLocation2(5)"> 南京 </div>
          <div class="newBox_li2" @click="toggleLocation2(6)"> 成都 </div>
          <div class="newBox_li2" @click="toggleLocation2(7)"> 武汉 </div>
        </div>
        <div class="newBox_juti">
          <img src="./assets/img/首页/location.png" alt="">
          <p id="newBox_juti2">上海徐汇区文定路208号A座</p>
        </div>
        <div class="newSa">
          <p>沪ICP备19007600号-2</p>
          <p>© 2019-2022 上海洛素文化艺术有限公司 版权所有</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      locationData: ["上海徐汇区文定路208号A座", "北京东城区南竹杆胡同2号银河SOHOB座", "广州市天河区中信广场7308室", "成都锦江区成都国际金融中心IFS一号楼"],
      locationData2: ["上海徐汇区文定路208号A座", "北京东城区南竹杆胡同2号银河SOHOB座", "广州市天河区中信广场7308室", "深圳福田区金田路东福华路北岗厦皇庭大厦", "杭州西湖区学院路77号黄龙万科中心", "南京秦淮区汉中路1号南京国际金融中心", "成都锦江区成都国际金融中心IFS一号楼", "武汉武昌区中北路9号长城汇"],
    }
  },
  methods: {
    toggleLocation(val) {
      var newBox_li = document.getElementsByClassName("newBox_li")
      newBox_juti.innerHTML = this.locationData[val]
      for (var i = 0; i < newBox_li.length; i++) {
        newBox_li[i].style.color = "#999"
        newBox_li[i].style.borderRightColor = "#999"
      }
      newBox_li[val].style.color = "#2ca6e0"
      newBox_li[val].style.borderRightColor = "#2ca6e0"
    },
    toggleLocation2(val) {
      var newBox_li = document.getElementsByClassName("newBox_li2")
      newBox_juti2.innerHTML = this.locationData2[val]
      for (var i = 0; i < newBox_li.length; i++) {
        newBox_li[i].style.color = "#999"
        newBox_li[i].style.borderRightColor = "#999"
      }
      newBox_li[val].style.color = "#2ca6e0"
      newBox_li[val].style.borderRightColor = "#2ca6e0"
    },
    handleNavitem(val) {
      var app_nav = document.getElementsByClassName("app_nav")
      var cha = document.getElementsByClassName("cha")

      console.log(val);
      if (app_nav[0].style.display == "flex") {
        // console.log("jin", cha[0].src);
        app_nav[0].style.display = "none"

      } else {
        app_nav[0].style.display = "flex"

      }
    },
    handleNav() {

      var cha = document.getElementsByClassName("cha")
      var app_nav = document.getElementsByClassName("app_nav")


      if (app_nav[0].style.display == "flex") {
        // console.log("jin");
        app_nav[0].style.display = "none"
        cha[0].src = require('./assets/img/首页/sanh.png')
      } else {
        app_nav[0].style.display = "flex"
        cha[0].src = require('./assets/img/首页/cha.png')
      }
    }
  },

}

</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  font-size: 13.3333vw;
  text-decoration: none;
  // overflow: hidden;
}

nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      background-color: #2ca6e0;

      .nav_quan {
        border-color: #fff;
      }

      .nav_text {
        color: #fff;
      }
    }
  }
}

.app_nav {
  width: 3.6rem;
  height: 8.52rem;
  // border: .01rem solid #000;
  top: .6rem;
  left: 0rem;
  display: none;
  z-index: 10;
  position: absolute;
  // display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 .06rem .06rem .03rem rgba(0, 0, 0, .2);

  a {
    width: 3.6rem;
    height: 1.22rem;
    border-bottom: .01rem dashed #e2e2e2;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: .24rem;
      height: .24rem;
      border: .02rem solid #000;
      border-radius: 50%;
      margin-right: .3rem;
    }

    p {
      width: 2rem;
      // border: .01rem solid #000;
      font-size: .36rem;
    }
  }
}

.app_title {
  width: 7.5rem;
  height: .6rem;
  // border: .01rem solid #000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .cha {
    width: .46rem;
    height: .46rem;
    position: absolute;
    left: .1rem;
    top: calc(50% - .23rem);
    display: block;
  }

  .rossotitle {
    width: 1.32rem;
    height: .38rem;
    margin: 0 auto;

  }
}

.newFooter {
  background-color: #393939;
  padding-top: .01rem;
  padding-bottom: .6rem;

  .newTop {
    width: 6.9rem;
    height: 1.15rem;
    border-bottom: .01rem solid hsla(0, 0%, 100%, .1);
    margin: .3rem auto 0;
    display: flex;
    justify-content: space-between;

    .newTitle {
      width: 3.12rem;
      color: #fff;

      // border: .01rem solid #000;
      .title {
        width: 3.12rem;
        font-size: .32rem;
      }

      .text {
        font-size: .2rem;
        margin-top: .1rem;
      }
    }

    .newRight {
      width: 3.2rem;
      display: flex;
      align-items: center;

      img {
        width: .22rem;
        height: .22rem;
      }

      p {
        font-size: .2rem;
        display: flex;
        align-items: center;
        color: #2ca6e0;

        span {
          font-size: .28rem;
          font-weight: bold;
        }
      }

    }
  }

  .newBox {
    width: 6.9rem;
    margin: .6rem auto;

    .newBox_title {
      font-size: .32rem;
      color: #ccc;
    }

    .newBox_ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: .46rem;

      .newBox_li {
        padding: 0 .48rem;
        font-size: .28rem;
        color: #999;
        margin-bottom: .46rem;
        // border-left: .02rem solid #999;
        border-right: .02rem solid #999;
      }

      .newBox_li:nth-of-type(1) {
        padding-left: 0;
        color: #2ca6e0;
        // border-left:none;
        border-right-color: #2ca6e0;
      }

      .newBox_li2 {
        padding: 0 .48rem;
        font-size: .28rem;
        color: #999;
        margin-bottom: .46rem;
        // border-left: .02rem solid #999;
        border-right: .02rem solid #999;
      }

      .newBox_li2:nth-of-type(1) {
        padding-left: 0;
        color: #2ca6e0;
        // border-left:none;
        border-right-color: #2ca6e0;
      }

      .newBox_li2:nth-of-type(5) {
        padding-left: 0;

      }
    }

    .newBox_juti {

      display: flex;
      align-items: center;
      margin-bottom: .6rem;

      img {
        width: .24rem;
        height: .27rem;
        margin-right: .1rem;
      }
    }

    #newBox_juti {
      font-size: .28rem;
      color: #2ca6e0;
    }

    #newBox_juti2 {
      font-size: .28rem;
      color: #2ca6e0;
    }

    .newSa {
      margin-top: 1rem;

      p {
        height: .4rem;
        line-height: .4rem;
        font-size: .2rem;
        color: #999;
      }
    }
  }
}</style>
