var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"h5"},[_vm._m(4),_c('div',{staticClass:"h5_title"},[_vm._v(" TOP音乐名校师资，为学员提供个性化授课服务 ")]),_c('div',{staticClass:"h5_toggle_box"},[_c('div',{staticClass:"h5_toggle_btn",on:{"click":function($event){return _vm.togglebtn(0)}}},[_vm._v("音乐海外教授")]),_c('div',{staticClass:"h5_toggle_btn",on:{"click":function($event){return _vm.togglebtn(1)}}},[_vm._v("音乐带头人")])]),_c('div',{staticClass:"h5_cont"},[_c('div',{staticClass:"swiper mySwiper2"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.musicTeacher),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('img',{attrs:{"src":'http://image.rossoarts.com/' + item.avatar,"alt":""}}),_c('p',{staticClass:"p1"},[_vm._v(" "+_vm._s(item.name))]),_c('p',{staticClass:"p2"},[_vm._v(" "+_vm._s(item.school))])])}),0)])])]),_c('div',{staticClass:"h7"},[_vm._m(5),_c('div',{staticClass:"h7_title"},[_vm._v(" 全球最in音乐资讯，一手掌握 ")]),_c('div',{staticClass:"h7_cont"},_vm._l((_vm.musicNews),function(item,index){return _c('div',{staticClass:"h7_item",on:{"click":function($event){return _vm.jopInfDetail(item.id)}}},[_c('img',{attrs:{"src":'http://image.rossoarts.com/' + item.list_img,"alt":""}}),_c('div',{staticClass:"h7_right"},[_c('p',{staticClass:"p1"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"p2"},[_c('img',{attrs:{"src":require("../assets/img/首页/biao.png"),"alt":""}}),_vm._v(_vm._s(item.create_time))])])])}),0),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home1"},[_c('div',{staticClass:"swiper mySwiper1"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide"},[_c('img',{attrs:{"src":require("../assets/img/首页/banner1.png"),"alt":""}})]),_c('div',{staticClass:"swiper-slide"},[_c('img',{attrs:{"src":require("../assets/img/首页/banner2.png"),"alt":""}})]),_c('div',{staticClass:"swiper-slide"},[_c('img',{attrs:{"src":require("../assets/img/首页/banner3.png"),"alt":""}})]),_c('div',{staticClass:"swiper-slide"},[_c('img',{attrs:{"src":require("../assets/img/首页/banner4.png"),"alt":""}})])]),_c('div',{staticClass:"swiper-pagination"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home2"},[_c('div',{staticClass:"h2_cont"},[_c('div',{staticClass:"h2_item"},[_c('img',{attrs:{"src":require("../assets/img/首页/h21.png"),"alt":""}}),_c('p',[_vm._v("热门院校")])]),_c('div',{staticClass:"h2_item"},[_c('img',{attrs:{"src":require("../assets/img/首页/h22.png"),"alt":""}}),_c('p',[_vm._v("热门专业")])]),_c('div',{staticClass:"h2_item"},[_c('img',{attrs:{"src":require("../assets/img/首页/h23.png"),"alt":""}}),_c('p',[_vm._v("音乐导师")])]),_c('div',{staticClass:"h2_item"},[_c('img',{attrs:{"src":require("../assets/img/首页/h24.png"),"alt":""}}),_c('p',[_vm._v("艺术留学")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h3"},[_c('div',{staticClass:"homoe_title"},[_c('img',{attrs:{"src":require("../assets/img/首页/titleicon.png"),"alt":""}}),_c('p',[_vm._v("ROSSO核心优势")]),_c('img',{attrs:{"src":require("../assets/img/首页/titleicon.png"),"alt":""}})]),_c('div',{staticClass:"h3_cont"},[_c('div',{staticClass:"h3_item"},[_c('img',{attrs:{"src":require("../assets/img/首页/h31.png"),"alt":""}}),_c('p',[_vm._v("国外TOP10院校师资力量")]),_c('div',[_vm._v("云集海内外知名音乐专家、教授，搭档顶尖音乐学院海归导师，为每一位学员提供个性化授课服务")])]),_c('div',{staticClass:"h3_item"},[_c('img',{attrs:{"src":require("../assets/img/首页/h32.png"),"alt":""}}),_c('p',[_vm._v("学院式课程体系")]),_c('div',[_vm._v("以学生为中心，注重多元化互动式教学体验，以1对1、多对1的形式提前让学生感受海外大学课程")])]),_c('div',{staticClass:"h3_item"},[_c('img',{attrs:{"src":require("../assets/img/首页/h33.png"),"alt":""}}),_c('p',[_vm._v("院校合作资源")]),_c('div',[_vm._v("与伯克利音乐学院、茱莉亚学院等多所海外名校建立合作，整合优质国际音乐教育资源")])]),_c('div',{staticClass:"h3_item"},[_c('img',{attrs:{"src":require("../assets/img/首页/h34.png"),"alt":""}}),_c('p',[_vm._v("海内外音乐实践")]),_c('div',[_vm._v("提供专业留学策划团队，为学员打造海内外竞赛、演出、实习、大师课等多品类音乐实践项目")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h4"},[_c('div',{staticClass:"homoe_title"},[_c('img',{attrs:{"src":require("../assets/img/首页/titleicon.png"),"alt":""}}),_c('p',[_vm._v("音乐留学特色服务")]),_c('img',{attrs:{"src":require("../assets/img/首页/titleicon.png"),"alt":""}})]),_c('div',{staticClass:"h4_title"},[_vm._v(" 音乐留学一站式规划，帮助学员收获理想OFFER ")]),_c('div',{staticClass:"h4_cont"},[_c('div',{staticClass:"h4_item"},[_c('img',{attrs:{"src":require("../assets/img/首页/h41.png"),"alt":""}}),_c('div',[_vm._v(" Interview指导 ")])]),_c('div',{staticClass:"h4_item"},[_c('img',{attrs:{"src":require("../assets/img/首页/h42.png"),"alt":""}}),_c('div',[_vm._v(" 夏校申请 ")])]),_c('div',{staticClass:"h4_item"},[_c('img',{attrs:{"src":require("../assets/img/首页/h43.png"),"alt":""}}),_c('div',[_vm._v(" 软实力提升 ")])]),_c('div',{staticClass:"h4_item"},[_c('img',{attrs:{"src":require("../assets/img/首页/h44.png"),"alt":""}}),_c('div',[_vm._v(" VIP专属家长会 ")])]),_c('div',{staticClass:"h4_item"},[_c('img',{attrs:{"src":require("../assets/img/首页/h45.png"),"alt":""}}),_c('div',[_vm._v(" 作品集录制 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"homoe_title"},[_c('img',{attrs:{"src":require("../assets/img/首页/titleicon.png"),"alt":""}}),_c('p',[_vm._v("音乐导师")]),_c('img',{attrs:{"src":require("../assets/img/首页/titleicon.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"homoe_title"},[_c('img',{attrs:{"src":require("../assets/img/首页/titleicon.png"),"alt":""}}),_c('p',[_vm._v("热门音乐资讯")]),_c('img',{attrs:{"src":require("../assets/img/首页/titleicon.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void(0)","onclick":"ydtopenchat()"}},[_c('div',{staticClass:"h7_more"},[_vm._v(" 查看更多 ")])])
}]

export { render, staticRenderFns }