import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: "ROSSO音乐留学-专注音乐类留学作品集培训机构" }
  },
  {
    path: '/works',
    name: 'works',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/WorksView.vue')
    },
    meta: { title: "音乐留学作品集培训机构_ROSSO音乐留学" }
  },
  {
    path: '/college',
    name: 'college',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/CollegeView.vue')
    },
    meta: { title: "音乐留学院校_世界音乐学院排名_ROSSO音乐留学" }
  },
  {
    path: '/major',
    name: 'major',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/MajorView.vue')
    },
    meta: { title: "音乐留学专业_ROSSO音乐留学" }
  },
  {
    path: '/tutor',
    name: 'tutor',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/TutorView.vue')
    },
    meta: { title: "音乐留学导师_音乐留学作品集培训_ROSSO音乐留学" }
  },
  {
    path: '/information',
    name: 'information',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/InformationView.vue')
    },
    meta: { title: "音乐留学资讯_音乐留学申请规划_ROSSO音乐留学" }
  },
 
  {
    path: '/majorDetail',
    name: 'majorDetail',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/majorDetail.vue')
    }
  },
  {
    path: '/inforDetail',
    name: 'inforDetail',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/inforDetail.vue')
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    window.scrollTo(0,0)
  }
})
router.afterEach(to => {
  // document.title = to.meta.title
  
  var app_nav = document.getElementsByClassName("app_nav")
  var cha = document.getElementsByClassName("cha")
  if(app_nav[0]){
    app_nav[0].style.display = "none"
    cha[0].src = require('../assets/img/首页/sanh.png')
  }

  
    document.title = to.meta.title

})

export default router
